import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  ControlFormFieldDirective,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  FormFieldComponent,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { Breakdown, Operation } from '@dougs/operations/dto';
import { NumberToStringPipe } from '../../../../pipes/number-to-string.pipe';
import { OperationVatComponentService } from '../../../../services/operation-vat.component.service';

@Component({
  selector: 'dougs-operation-vat',
  templateUrl: './operation-vat.component.html',
  styleUrls: ['./operation-vat.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormFieldComponent,
    DropdownTriggerDirective,
    DropdownComponent,
    DropdownOptionComponent,
    ReactiveFormsModule,
    FormsModule,
    ControlFormFieldDirective,
    SuffixFormFieldDirective,
  ],
  providers: [OperationVatComponentService, NumberToStringPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationVatComponent {
  constructor(public readonly operationVatComponentService: OperationVatComponentService) {}

  @Input() isCompact = false;

  @Input()
  set operation(operation: Operation) {
    this.operationVatComponentService.operation = operation;
  }

  @Input()
  set breakdown(breakdown: Breakdown) {
    this.operationVatComponentService.breakdown = breakdown;
  }
}
