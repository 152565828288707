import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  FileInputComponent,
  FilePillComponent,
  SalesInvoicePillComponent,
  SourceDocumentPillComponent,
  TrackByPipe,
  VendorInvoicePillComponent,
} from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { UserStateService } from '@dougs/user/shared';
import { OperationFilesComponentService } from '../../../../services/operation-files.component.service';

@Component({
  selector: 'dougs-operation-files',
  templateUrl: './operation-files.component.html',
  styleUrls: ['./operation-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FileInputComponent,
    FilePillComponent,
    TrackByPipe,
    VendorInvoicePillComponent,
    SalesInvoicePillComponent,
    SourceDocumentPillComponent,
  ],
  providers: [OperationFilesComponentService],
})
export class OperationFilesComponent {
  @Input() isInOperationListModal = false;
  @Input() mixpanelModalName?: string;
  @Input() enableFileAnimation?: boolean;
  @Input() disableReceiptMatching = false;

  @Input() set operation(operation: Operation) {
    this.operationFilesComponentService.setOperation(operation);
  }

  constructor(
    public readonly userStateService: UserStateService,
    public readonly operationFilesComponentService: OperationFilesComponentService,
  ) {}
}
