<ng-container *ngIf="ecommerceOperationSynchronizationService.updateErrorReport$ | async"></ng-container>

<ng-container
  *ngIf="{
    operation: ecommerceOperationSynchronizationService.operation$ | async,
    isSynchronized: ecommerceOperationSynchronizationService.isSynchronized$ | async,
    hasSalesError: ecommerceOperationSynchronizationService.hasSalesError$ | async
  } as data"
>
  <div class="sync-status p-16 mx-auto">
    <p *ngIf="data.operation.metadata.shopifySynchStatus?.lastUpdatedDate" class="tiny">
      Dernière mise à jour le
      {{ data.operation.metadata.shopifySynchStatus?.lastUpdatedDate | date: 'dd/MM/yyyy' | titlecase }} à
      {{ data.operation.metadata.shopifySynchStatus?.lastUpdatedDate | date: 'HH:mm' | titlecase }}
    </p>
    <div
      *ngIf="ecommerceOperationSynchronizationService.isErrorReportLoaded$()"
      class="sync-status__content"
      @slideUpDown
    >
      <dougs-panel-info
        *ngIf="data.operation.metadata.shopifySynchStatus?.status === 'success' && data.hasSalesError; else syncStatus"
        type="warning"
        class="mt-16 sync-status__content__panel"
      >
        <i class="fal fa-exclamation-circle color-warning mr-8"></i>
        <div>
          <h6>Des ventes ont été corrigées pour être comptabilisées.</h6>
          <p class="small">
            Il vous reste à remplir vos éventuels commissions et frais divers, à associer vos justificatifs, puis à
            valider votre ventilation.
          </p>
        </div>
        <span
          (click)="ecommerceOperationSynchronizationService.openSalesAdjustmentModal()"
          class="sync-status__content__panel__detail-link pointer underline"
        >
          Voir le détail
        </span>
      </dougs-panel-info>
      <ng-template #syncStatus>
        <dougs-panel-info
          *ngIf="data.operation.metadata.shopifySynchStatus"
          [type]="data.operation.metadata.shopifySynchStatus.status"
          class="sync-status__content__panel mt-16"
        >
          <ng-container [ngSwitch]="data.operation.metadata.shopifySynchStatus.status">
            <i *ngSwitchCase="'success'" class="fal fa-check-circle color-success mr-8"></i>
            <i *ngSwitchCase="'error'" class="fal fa-times-circle color-error mr-8"></i>
            <i *ngSwitchDefault class="fal fa-info-circle color-primary mr-8"></i>
          </ng-container>
          <div>
            <h6>{{ data.operation.metadata.shopifySynchStatus.title }}</h6>
            <p class="small">{{ data.operation.metadata.shopifySynchStatus.message }}</p>
          </div>
        </dougs-panel-info>
      </ng-template>
    </div>
  </div>
</ng-container>
