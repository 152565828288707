import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, map, Observable, Subject } from 'rxjs';
import { evalString, stringToNumber } from '@dougs/core/utils';
import { Breakdown, Category, Operation, OperationSection } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
import { OperationComponentService } from '../services/operation.component.service';
import { BreakdownService } from './breakdown.service';
import { OperationDetailsComponentService } from './operation-details.component.service';

@Injectable()
export class OperationBreakdownComponentService {
  private readonly _breakdown: BehaviorSubject<Breakdown | null> = new BehaviorSubject<Breakdown | null>(null);
  private _currentCategory!: Category;
  private _signedAmount!: number;
  private _section!: OperationSection;
  private readonly _disabledModification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _showRemoveBreakdownButton: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _isBreakdownVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private readonly _showBreakdownMetadata: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _operation!: Operation;
  private _forceEditable = false;
  private readonly operationAction: Subject<boolean> = new Subject<boolean>();

  breakdown$: Observable<Breakdown | null> = this._breakdown.asObservable();
  disabledModification$: Observable<boolean> = this._disabledModification.asObservable();
  isBreakdownVisible$: Observable<boolean> = this._isBreakdownVisible.asObservable();
  showBreakdownMetadata$: Observable<boolean> = this._showBreakdownMetadata.asObservable();
  showRemoveBreakdownButton$: Observable<boolean> = this._showRemoveBreakdownButton.asObservable();
  emitOperationAction$: Observable<boolean> = this.operationAction.asObservable();

  onRemoveBreakdown$: Observable<void> = this.operationDetailsComponentService.lastBreakdown$.pipe(
    filter((breakdown) => breakdown.id === this._breakdown?.value?.id),
    map(() => this.onRemoveBreakdown()),
  );

  constructor(
    private readonly operationService: OperationService,
    private readonly breakdownService: BreakdownService,
    private readonly operationComponentService: OperationComponentService,
    private readonly operationDetailsComponentService: OperationDetailsComponentService,
  ) {}

  set breakdown(breakdown: Breakdown) {
    this._breakdown.next(breakdown);
    this._disabledModification.next(this.shouldDisableModification(this._operation, breakdown, this._forceEditable));
    this._currentCategory = this.getBreakdownCategory(breakdown);
    this._showRemoveBreakdownButton.next(this.breakdownService.canRemoveBreakdown(this._operation, breakdown));
    this._isBreakdownVisible.next(
      this._isBreakdownVisible.value && this.breakdownService.shouldShowBreakdown(breakdown),
    );
    this._showBreakdownMetadata.next(this.breakdownService.shouldShowBreakdownMetadata(breakdown));
    this._signedAmount = this.breakdownService.getSignedAmount(this._operation, breakdown, this._section);
  }

  get operation(): Operation {
    return this._operation;
  }

  set operation(value: Operation) {
    this._operation = value;
  }

  set forceEditable(value: boolean) {
    this._forceEditable = value;
  }

  get currentCategory(): Category {
    return this._currentCategory;
  }

  get signedAmount(): number {
    return this._signedAmount;
  }

  set section(value: OperationSection) {
    this._section = value;
  }

  onCategoryChange(category: Category): void {
    this.operationAction.next(true);
    this.operationComponentService.updateCategory(this._breakdown.value, category);
  }

  onAmountChange(signedAmount: string): void {
    this.operationAction.next(true);
    this._signedAmount = stringToNumber(evalString(signedAmount));
    this.operationComponentService.updateAmount(this._breakdown.value, this._signedAmount, this._section);
  }

  onRemoveBreakdown(): void {
    this.operationAction.next(true);
    this._isBreakdownVisible.next(false);
    this.operationComponentService.removeBreakdown(this._breakdown.value as Breakdown); // TODO handle null breakdown
  }

  private getBreakdownCategory(breakdown: Breakdown): Category {
    return {
      id: breakdown.categoryId,
      wording: breakdown.categoryWording,
    } as Category;
  }

  private shouldDisableModification(operation: Operation, breakdown: Breakdown, forceEditable: boolean): boolean {
    return !this.operationService.isCategoryEditable(operation, breakdown) && !forceEditable;
  }
}
