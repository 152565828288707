<ng-container *ngIf="operationEcommerceDispatchFilesComponentService.refreshSalesChannels$ | async"></ng-container>
<div
  *ngIf="operationEcommerceDispatchFilesComponentService.shouldShow$ | async"
  class="operation-ecommerce-dispatch-files mt-24 mx-80 py-16 px-24"
>
  <p class="small operation-ecommerce-dispatch-files__title mb-16">
    Importez votre Tax Report
    <img
      class="operation-ecommerce-dispatch-files__title__amazon mx-4"
      src="images/illustrations/amazon.png"
      alt="Amazon"
    />
    et laissez la magie opérer...
    <img
      class="ml-4 operation-ecommerce-dispatch-files__title__magic-wand"
      src="images/emoji/magic-wand.png"
      alt="magic wand"
    />
  </p>

  <p
    class="tiny mb-16 operation-ecommerce-dispatch-files__loader"
    *ngIf="operationsStateService.isTaxReportLoading$ | async"
  >
    <dougs-loader appearance="spin"></dougs-loader>
    <span class="ml-4">Chargement de votre Tax Report en cours</span>
  </p>

  <dougs-panel-info
    type="success"
    class="operation-ecommerce-dispatch-files__info mb-16"
    *ngIf="
      (operationEcommerceDispatchFilesComponentService.isTaxReportProcessed$ | async) &&
      operationEcommerceDispatchFilesComponentService.operation.metadata.status === 'success'
    "
  >
    <i class="fal fa-check-circle color-success mr-8"></i>
    <div>
      <h6>Tax Report traité avec succès</h6>
      <p class="tiny">
        Vous n’avez plus qu’à remplir vos commissions Amazon et les chiffres de vos autres plateformes e-commerce (si
        vous en avez).
      </p>
    </div>
  </dougs-panel-info>

  <dougs-panel-info
    type="warning"
    class="operation-ecommerce-dispatch-files__info mb-16"
    *ngIf="
      (operationEcommerceDispatchFilesComponentService.isTaxReportProcessed$ | async) &&
      operationEcommerceDispatchFilesComponentService.operation.metadata.status === 'warning'
    "
  >
    <i class="fal fa-exclamation-circle color-warning mr-8"></i>
    <div>
      <h6>Anomalie dans le Tax Report</h6>
      <p class="tiny">
        Des ventes ont été corrigées dans le respect de vos obligations fiscales pour être comptabilisées
      </p>
    </div>
  </dougs-panel-info>

  <dougs-panel-info
    type="error"
    class="operation-ecommerce-dispatch-files__info mb-16"
    *ngIf="
      !(operationEcommerceDispatchFilesComponentService.isTaxReportProcessed$ | async) &&
      !(operationsStateService.isTaxReportLoading$ | async) &&
      (operationEcommerceDispatchFilesComponentService.hasTaxReport$ | async)
    "
  >
    <i class="fal fa-times-circle color-error mr-8"></i>
    <div>
      <h6>Erreur dans le traitement du Tax Report</h6>
      <p class="tiny">
        Merci de nous contacter pour régler le problème.
        <br />
        <span *ngIf="(userStateService.loggedInUser$ | async).isAccountantOrAdmin">
          {{ operationEcommerceDispatchFilesComponentService.adminErrorMessage$ | async }}
        </span>
      </p>
    </div>
  </dougs-panel-info>
  <dougs-panel-info
    *ngIf="!(operationEcommerceDispatchFilesComponentService.hasAmazonEu$ | async); else hasSalesChannel"
  >
    Pour importer un Tax Report, vous devez créer au préalable un canal de vente Amazon Europe
    <a href="#" (click)="operationEcommerceDispatchFilesComponentService.openCreateSalesChannelModal($event)">
      Créez-le ici
    </a>
  </dougs-panel-info>

  <ng-template #hasSalesChannel>
    <div class="file-list">
      <div class="file-upload__input" *ngIf="!(operationEcommerceDispatchFilesComponentService.hasTaxReport$ | async)">
        <dougs-file-input
          [multiple]="false"
          (uploadFiles)="
            operationEcommerceDispatchFilesComponentService.onUploadFiles(
              operationEcommerceDispatchFilesComponentService.operation,
              $event
            )
          "
        ></dougs-file-input>
      </div>
      <div *ngIf="operationEcommerceDispatchFilesComponentService.amazonTaxReportFileAttachment">
        <dougs-source-document-pill
          [sourceDocumentAttachment]="operationEcommerceDispatchFilesComponentService.amazonTaxReportFileAttachment"
          [canDelete]="!operationEcommerceDispatchFilesComponentService.operation.validated"
          (deleteSourceDocumentAttachment)="
            operationEcommerceDispatchFilesComponentService.onDeleteFile(
              operationEcommerceDispatchFilesComponentService.operation,
              $event
            )
          "
        ></dougs-source-document-pill>
      </div>
    </div>
  </ng-template>
</div>
